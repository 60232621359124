import {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import {
  SideTabRef,
  useAppBaseContext,
  useGetCompany,
  useGetScopes,
  useGetUser,
  User,
  useUserStore,
} from '@omnipkg/pa-ui-kit';
import {
  QueryObserverResult,
  RefetchOptions,
  useQueries,
} from '@tanstack/react-query';

import { useGetUserLegacy } from '@src/api/getUserLegacy';
import { QueryKey } from '@src/types/keys';

interface ContextProps {
  children: React.ReactNode;
}

interface CommonContextValue {
  isDataReady: boolean;
  isUserFetching: boolean;
  termsConditionsRef: RefObject<SideTabRef>;
  openTermsConditions: () => void;
  userRefetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<User, Error>>;
}

const Context = createContext<CommonContextValue | null>(null);

export const CommonContextProvider = ({ children }: ContextProps) => {
  const [isDataReady, setIsDataReady] = useState(false);
  const { isAuthDataLoading, isTokenSet } = useAppBaseContext();
  const { setCompany, setUser, setScopes, setUserLegacyUid } = useUserStore();

  const termsConditionsRef = useRef<SideTabRef>(null);

  const getUser = useGetUser();
  const getCompany = useGetCompany();
  const getScopes = useGetScopes();
  const getUserLegacy = useGetUserLegacy();

  const [
    { refetch: userRefetch, isFetching: isUserFetching },
    { refetch: companyRefetch },
    { refetch: scopesRefetch },
    { refetch: userLegacyRefetch },
  ] = useQueries({
    queries: [
      {
        enabled: false,
        queryKey: [QueryKey.INIT_USER],
        queryFn: () =>
          getUser({ baseURL: import.meta.env.VITE_OIDC_AUTHORITY }),
      },
      {
        enabled: false,
        queryKey: [QueryKey.INIT_COMPANY],
        queryFn: () => getCompany(),
      },
      {
        enabled: false,
        queryKey: [QueryKey.INIT_SCOPES],
        queryFn: () =>
          getScopes({ baseURL: import.meta.env.VITE_OIDC_AUTHORITY }),
      },
      {
        enabled: false,
        queryKey: [QueryKey.INIT_LEGACY_USER],
        queryFn: () => getUserLegacy(),
      },
    ],
  });

  const getCommonData = useCallback(() => {
    Promise.all([
      userRefetch(),
      companyRefetch(),
      scopesRefetch(),
      userLegacyRefetch(),
    ])
      .then(
        ([
          { data: user },
          { data: company },
          { data: scopes },
          { data: userLegacy },
        ]) => {
          if (user && company && scopes && userLegacy) {
            setUser(user);
            setCompany(company);
            setScopes(scopes.allowed_scopes);
            setUserLegacyUid(userLegacy.uid);

            setIsDataReady(true);
          } else {
            throw new Error('Error while fetching common data');
          }
        },
      )
      .catch((error) => {
        toast.error(error.message);
      });
  }, [
    userRefetch,
    companyRefetch,
    scopesRefetch,
    userLegacyRefetch,
    setUser,
    setCompany,
    setScopes,
    setUserLegacyUid,
  ]);

  const openTermsConditions = useCallback(() => {
    termsConditionsRef.current?.open();
  }, []);

  useEffect(() => {
    if (!isAuthDataLoading && isTokenSet) {
      getCommonData();
    }
  }, [isAuthDataLoading, isTokenSet, getCommonData]);

  const value = useMemo(() => {
    return {
      isDataReady,
      isUserFetching,
      termsConditionsRef,
      openTermsConditions,
      userRefetch,
    };
  }, [
    isDataReady,
    isUserFetching,
    termsConditionsRef,
    openTermsConditions,
    userRefetch,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCommonContext(): CommonContextValue {
  const contextData = useContext(Context);
  if (!contextData)
    throw new Error(
      'Please use hook useCommonContext inside CommonContextProvider provider',
    );
  return contextData;
}
